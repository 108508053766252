export function pagetopPosition() {
	window.addEventListener("load", () => {
		const pageTopBtn = document.getElementById('pagetop'),
		      footer = document.querySelector(".footer"),
		      scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		let nowY = window.pageYOffset + window.innerHeight,
				rect = footer.getBoundingClientRect(),
				pageTopPos = rect.top + scrollTop + 10;

		function scrollPos() {
			nowY = window.pageYOffset + window.innerHeight;
			if(nowY >= pageTopPos){
				pageTopBtn.classList.add('is-position');
			} else {
				pageTopBtn.classList.remove('is-position');
			}
		}

		window.addEventListener('scroll', () => {
			if(window.pageYOffset >= 100){
				pageTopBtn.classList.add('is-active');
			} else {
				pageTopBtn.classList.remove('is-active');
			}

			scrollPos();
		});
	});
}
