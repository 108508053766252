export function changeImg() {
	let wid = window.innerWidth,
			resizeTimer = false;

	/* change Img
	*********************************************/
	const changeImg = document.getElementsByClassName('change-img');
	function changeImgSp(){
		for (let i = 0; i < changeImg.length; i++) {
			changeImg[i].setAttribute("src", changeImg[i].getAttribute("src").replace(/_pc\./, '_sp.'));
		}
	}
	function changeImgPc(){
		for (let i = 0; i < changeImg.length; i++) {
			changeImg[i].setAttribute("src", changeImg[i].getAttribute("src").replace(/_sp\./, '_pc.'));
		}
	}

	function descriminateBp(){
		wid = window.innerWidth;
		if(matchMedia('(min-width: 769px)').matches){
			changeImgPc();
		} else {
			changeImgSp();
		}
	}
	descriminateBp();

	window.addEventListener('resize', () => {
		if(wid > window.innerWidth || wid < window.innerWidth){
			if (resizeTimer !== false) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(descriminateBp, 100);
		}
	});
}
