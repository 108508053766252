//import '@babel/polyfill';

//import {common} from "../modules/common";
import {changeImg} from "../modules/changeImg";
import {smoothScroll} from "../modules/smoothScroll";
//import {smoothLink} from "../modules/smoothLink";
import {scrollAnimation} from "../modules/scrollAnimation";
import {nav} from "../modules/nav";
import {navChild} from "../modules/navChild";
import {pagetop} from "../modules/pagetop";
import {pagetopPosition} from "../modules/pagetopPosition";

import {swiper} from "../modules/swiper";
import {formError} from "../modules/formError";

//common();
changeImg();
smoothScroll();
//smoothLink();
scrollAnimation();
nav();
navChild();
pagetop();
pagetopPosition();
swiper();
formError();
