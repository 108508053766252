/* nav open/close
*********************************************/
export function nav() {
	const navBtn = document.querySelector('.nav__btn'),
				navWrap = document.querySelector('.nav__wrap'),
				navChildBtn = document.getElementsByClassName('nav-child__btn');

  function navA11y() {
		if(navBtn.classList.contains('is-opened')){
      navBtn.setAttribute('aria-label', 'グローバルナビゲーションを閉じる');
    } else {
			navBtn.setAttribute('aria-label', 'グローバルナビゲーションを開く');
    }
	}

	navBtn.addEventListener('click', () => {
		navBtn.classList.toggle('is-opened');
		navWrap.classList.toggle('is-opened');
		document.body.classList.toggle('is-nav');

		navA11y();
	}, false);

	for(let i = 0; i < navChildBtn.length; i++) {
		navChildBtn[i].addEventListener('click', () => {
			navBtn.classList.remove('is-opened');
			navWrap.classList.remove('is-opened');
			document.body.classList.remove('is-nav');

			navA11y();
		}, false);
	}
}
