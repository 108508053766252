export function pagetop() {
	const pageTopBtn = document.getElementById('pagetop');

	pageTopBtn.addEventListener("click", function goTop() {
	  const nowY = window.pageYOffset;

	  window.scrollTo(0, Math.floor(nowY * 0.9));
	  if (nowY > 0) {
	    window.setTimeout(goTop, 10);
	  }
	});
}
