export function navChild() {
	let wid = window.innerWidth,
			newW;
	const navParent = document.getElementsByClassName('nav__parent'),
				navChild = document.getElementsByClassName('nav-child');

	for(let i = 0; i < navParent.length; i++) {
		navParent[i].addEventListener('mouseenter', () => {
			navChild[i].classList.add('is-active');
		}, false);
		navParent[i].addEventListener('mouseleave', () => {
			navChild[i].classList.remove('is-active');
		}, false);

		navChild[i].addEventListener('mouseenter', () => {
			navChild[i].classList.add('is-active');
		}, false);
		navChild[i].addEventListener('mouseleave', () => {
			navChild[i].classList.remove('is-active');
		}, false);


		navParent[i].addEventListener('click', () => {
			navParent[i].classList.toggle('is-opened');
			navChild[i].classList.toggle('is-opened');
		}, false);
	}

	function navReset(){
		for(let i = 0; i < navChild.length; i++) {
			navParent[i].classList.remove('is-opened');
			navChild[i].classList.remove('is-opened');
			navChild[i].classList.remove('is-active');
		}
	}

	window.addEventListener('resize', () => {
		newW = window.innerWidth;
		if(wid > 768 && newW <= 768){
			navReset();
		} else if(wid < 769 && newW >= 769) {
			navReset();
		}
		wid = newW;
	});
}
