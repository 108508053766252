export function swiper() {
	let storySlide = new Swiper('.top-mv__side', {
		autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
	  loop: true,
		speed: 1000,
	  autoHeight: false,
		centeredSlides: true,
		resizeReInit: true,
		pagination: {
	    el: '.swiper-pagination',
	    type: 'bullets',
			clickable: true,
	  },
	});
}
